import { COLORS } from 'styles/constants';
import { Text as Paragraph } from '../../Typography';
import { FilterTopic } from '../types';
import DisplayStars from './DisplayStars';

const TopicOptions = ({
  filterTopic,
  onToggleFilter,
}: {
  filterTopic: FilterTopic;
  onToggleFilter: (key: string, isSelected: boolean) => void;
}) => {
  const data =
    filterTopic.type === 'rating'
      ? filterTopic?.options?.filter((i) => i.key !== 'rating_effective:0')
      : filterTopic.options;

  return (
    <div key={filterTopic.key} className="pb-[0.625rem]">
      {data.map((opt) => (
        <label htmlFor={opt.key} key={opt.key} className="mt-0 mb-1 flex flex-row items-center">
          <input
            type="checkbox"
            id={opt.key}
            name={opt.key}
            onChange={() => onToggleFilter(opt.key, !opt.selected)}
            value={opt.key}
            data-test={`filter-${filterTopic.key}-opt-${opt.key}`}
            checked={opt.selected}
            className={`w-[34px] h-[34px] border-2 border-grayBorder rounded mr-5 checked:border-[${COLORS.primary}] checked:bg-dark-brown`}
          />
          <Paragraph tag="span" type="md" className={opt.selected ? 'font-medium' : ''}>
            {filterTopic.type === 'rating' && (
              <DisplayStars r={opt.key.slice(opt.key.length - 1)} />
            )}
            {opt.label} ({opt.itemCount})
          </Paragraph>
        </label>
      ))}
    </div>
  );
};

export default TopicOptions;
